import { Address } from '@celo/base/lib/address'
import axios from 'axios'

import { NFTInfo } from './nftTypes'
import { NFTIndex } from './nftIndex'
import { ignoredNftContracts } from './nftList'

export class ValoraNFTIndex implements NFTIndex {
  private baseUrl: string

  constructor({ baseUrl }: { baseUrl?: string }) {
    this.baseUrl = baseUrl || 'https://api.mainnet.valora.xyz'
  }

  async balance({ address, networkId }: { address: Address; networkId: string }): Promise<NFTInfo[]> {
    const nftInfos: NFTInfo[] = []

    const result = await axios.get(`${this.baseUrl}/getNfts?address=${address}&networkId=${networkId}`)
    for (const nftResult of result.data.result) {
      const nftInfo: NFTInfo = {
        collectionAddress: nftResult.contractAddress,
        owner: address,
        tokenId: nftResult.tokenId,
        tokenUri: nftResult.tokenUri,
        imageUrl: nftResult.metadata?.image,
        name: nftResult.metadata?.name,
        ignored: ignoredNftContracts.has(nftResult.contractAddress),
        verified: nftResult.recognized,
        raw: nftResult,
      }
      nftInfos.push(nftInfo)
    }

    return nftInfos
  }
}
