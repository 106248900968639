import { useState } from 'react'
import { useAsync } from 'react-async-hook'
import { isValidAddress } from 'helpers/address'
import { StorageKey, saveValue, readValue } from 'helpers/storage'
import App from 'app/App'

function Router() {
  const [address, setAddress] = useState('')
  const isValoraWebView = /^Valora-\d*[.]\d*[.]\d*$/.test(navigator.userAgent)
  const hideHeader = isValoraWebView || !!new URLSearchParams(window.location.search).get('hide-header')
  const networkId = new URLSearchParams(window.location.search).get('networkId') ?? 'celo-mainnet'

  const updateAddress = (newAddress: string) => {
    console.log(window.location.pathname)
    setAddress(newAddress)
    const urlSearchParams = new URLSearchParams(window.location.search)
    urlSearchParams.set('address', newAddress)
    window.history.replaceState(null, '', `?${urlSearchParams.toString()}`)
  }

  useAsync(async () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    const address: string | null = params.address
    if (await isValidAddress(address)) {
      updateAddress(address)
      saveValue(StorageKey.Address, address)
      return
    }
    const storedAddress = readValue(StorageKey.Address)
    if (storedAddress) {
      updateAddress(storedAddress)
    }
  }, [])

  return <App address={address} hideHeader={hideHeader} updateAddress={updateAddress} networkId={networkId} />
}

export default Router
