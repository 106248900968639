import { useState } from 'react'
import { useAppSelector } from 'store/store'
import { NFT_QUERY_LIMIT } from 'constants/constants'

export function useLoadNFTs() {
  const { nftsInfo, loading, error } = useAppSelector((state) => state.nfts)
  const [currentPage, setCurrentPage] = useState(0)

  const loadedNFTs = nftsInfo.slice(0, (currentPage + 1) * NFT_QUERY_LIMIT)
  const hasNextPage = loadedNFTs.length != nftsInfo.length

  const loadMore = () => {
    setCurrentPage(currentPage + 1)
  }

  return { loading, nfts: loadedNFTs, hasNextPage, error, loadMore }
}
