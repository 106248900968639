import Header from 'app/components/Header'
import Gallery from 'app/pages/Gallery'
import Landing from 'app/pages/Landing'
import { useEffect } from 'react'
import { fetchNFTs, setActiveNFT } from '../store/nfts'
import { useAppDispatch, useAppSelector } from '../store/store'
import NFTDetails from './pages/Gallery/NFTDetails'

interface Props {
  address: string
  updateAddress: (address: string) => void
  hideHeader: boolean
  networkId: string
}

function App({ address, updateAddress, hideHeader, networkId }: Props) {
  const { loading } = useAppSelector((state) => state.nfts)
  const activeNft = useAppSelector((state) => state.nfts.activeNFT)
  const header = !hideHeader ? <Header isGallery={!!address} /> : null
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (address) {
      dispatch(fetchNFTs({ address, networkId }))
    }
  }, [address])

  useEffect(() => {
    // Fix partial blank page served on back press
    window.history.scrollRestoration = 'manual'
    window.history.pushState(null, '', document.URL)
    window.addEventListener('popstate', () => {
      dispatch(setActiveNFT(null))
    })
  }, [])

  return (
    <>
      {header}
      {address ? (
        activeNft ? (
          <NFTDetails nft={activeNft} hideHeader={hideHeader} networkId={networkId} />
        ) : (
          <Gallery address={address} loading={loading} updateAddress={updateAddress} networkId={networkId} />
        )
      ) : (
        <Landing updateAddress={updateAddress} networkId={networkId} />
      )}
    </>
  )
}

export default App
