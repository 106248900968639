import { ContractKit, newKit } from '@celo/contractkit'

let contractKit: ContractKit
export async function getContractKit(): Promise<ContractKit> {
  if (contractKit && (await contractKit.connection.isListening())) {
    return contractKit
  } else {
    contractKit = newKit('https://forno.celo.org')
    return contractKit
  }
}

export async function contract(contractAbi: any[], address: string) {
  const kit = await getContractKit()
  // Mapping the abi is a workaround for a weird web3 error. wtf.
  return new kit.web3.eth.Contract(
    contractAbi.map((method) => ({ ...method })),
    address,
  )
}
