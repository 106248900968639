import styles from './Header.module.scss'
import ReactGA from 'react-ga4'

const DOWNLOAD_VALORA_LINK = 'https://vlra.app/nft-viewer'

export const Header = ({ isGallery }: { isGallery: boolean }) => {
  const onDownloadValoraPressed = () => {
    ReactGA.event('Download_Valora_Tapped')
    window.open(DOWNLOAD_VALORA_LINK, '_blank')
  }

  return (
    <header className={`${styles.container} ${isGallery ? styles.background : ''}`}>
      <button className={styles.button} data-cy="Download-Valora" onClick={onDownloadValoraPressed}>
        Download Valora
      </button>
    </header>
  )
}
