import { Card, Link, Typography } from '@mui/material'
import { networkInfos } from 'constants/constants'
import { imageUrlFormat } from 'helpers/utils'
import { useCallback, useEffect, useMemo } from 'react'
import ReactGA from 'react-ga4'
import icon from '../../../assets/goto-icon.svg'
import styles from './NFTDetails.module.scss'

interface Props {
  nft: any
  hideHeader: any
  networkId: string
}

export function getGatewayUrl({ nft, url }: { nft: any; url: string }): string {
  if (!nft?.raw?.media) {
    return url
  }
  const media = nft.raw.media.find((media: any) => media.raw === url)
  return media ? media.gateway : url
}

export function NFTDetails({ nft, hideHeader, networkId }: Props) {
  const networkInfo = networkInfos[networkId]
  const hasExternalView = nft.collectionAddress && nft.tokenId && networkInfo

  const explorerLinkClicked = () => {
    ReactGA.event('Explorer_Link_Clicked', {
      category: 'Gallery/NFT_Details',
      action: 'Explorer Link Clicked',
    })
  }

  useEffect(() => {
    // Force scroll to top of page and timeout helps with race conditions
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
    }, 0)
    // Populate history with current url
    window.history.pushState(null, '', document.URL)
    // Google Analytics
    ReactGA.event('NFT_Details_Displayed', {
      category: 'Gallery/NFT_Details',
      action: 'NFT Details displayed',
      nft_name: `${nft.name}`,
      collection_address: `${nft.collectionAddress}`,
    })
  }, [])

  const imageUrl = useMemo(() => getGatewayUrl({ nft, url: nft.imageUrl }), [nft])
  const handleImageError = useCallback(
    (e) =>
      ReactGA.event('NFT_Image_Load_Error', {
        category: 'Gallery/NFTs',
        action: 'NFTs Image Failed to Load',
        image_host: imageUrl ? new URL(imageUrl).hostname : '',
      }),
    [imageUrl],
  )

  let animationUrl = null
  if (nft?.raw?.metadata?.animation_url) {
    animationUrl = getGatewayUrl({ nft, url: nft.raw.metadata.animation_url })
  }

  // If there's an animation asset use a video element.
  const nftAssetElement = animationUrl ? (
    <video
      controls
      autoPlay
      playsInline
      className={styles.nftImage}
      height="auto"
      onError={handleImageError}
      crossOrigin="anonymous"
    >
      <source src={animationUrl} />
      {`${nft.name} animation`}
    </video>
  ) : (
    <img
      className={styles.nftImage}
      height="auto"
      src={imageUrlFormat(imageUrl)}
      alt={`${nft.name} image`}
      onError={handleImageError}
      crossOrigin="anonymous"
    />
  )

  return (
    <>
      <section className={styles.container} data-testid="nft-info" aria-label="NFT details">
        <div style={hideHeader ? { marginTop: 0 } : { marginTop: '64px' }} className={styles.imageRow}>
          <Card variant={'outlined'} sx={{ margin: '10px' }}>
            {nftAssetElement}
            <Typography noWrap className={styles.nftTitle} variant="h4" component="div">
              {nft.name}
            </Typography>
            {nft.attributes && nft.attributes.length && (
              <>
                <Typography className={styles.nftAttributesHeader} variant="h4" component="div">
                  Attributes
                </Typography>
                <section style={{ display: 'flex' }}>
                  <div className={styles.nftAttributesChipContainer}>
                    {nft.attributes.map((attribute: { trait_type: string; value: string }, idx: number) => {
                      return (
                        <div key={idx} className={styles.nftAttributesChip}>
                          <Typography className={styles.nftAttributeType}>{attribute.trait_type}</Typography>
                          <Typography className={styles.nftAttributeValue}>{attribute.value}</Typography>
                        </div>
                      )
                    })}
                  </div>
                </section>
              </>
            )}
            {hasExternalView && (
              <Typography className={styles.explorerLinkContainer} variant="h5" component="div">
                <Link
                  onClick={explorerLinkClicked}
                  href={networkInfo.getExternalViewUrl(nft.collectionAddress, nft.tokenId)}
                  className={styles.explorerLink}
                  target="_blank"
                  rel="noopener"
                  underline="none"
                >
                  {networkInfo.externalViewDescription}
                  <img src={icon} />
                </Link>
              </Typography>
            )}
          </Card>
        </div>
      </section>
    </>
  )
}
