import { Card, CardMedia, Typography } from '@mui/material'
import { useLoadNFTs } from 'app/hooks/useLoadNFTs'
import { imageUrlFormat } from 'helpers/utils'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useAppSelector } from 'store/store'
import { setActiveNFT } from '../../../../store/nfts'
import { useAppDispatch } from '../../../../store/store'
import styles from './NFTs.module.scss'
import { getGatewayUrl } from '../NFTDetails'

export function NFTs({ address }: { address: string }) {
  const { nftCollections } = useAppSelector((state) => state.nfts)
  const dispatch = useAppDispatch()

  const { loading, nfts, hasNextPage, error, loadMore } = useLoadNFTs()

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
  })

  const nftsToShow = nfts.filter((nft) => nft.imageUrl).filter((nft) => !nftCollections[nft.collectionAddress]?.ignore)

  const noNftsFound = !loading && !error && !nftsToShow.length

  useEffect(() => {
    if (!loading && !error && nftsToShow.length) {
      ReactGA.event('NFTs_Displayed', {
        category: 'Gallery/NFTs',
        action: 'NFTs Displayed',
        num_nfts: `${nftsToShow.length}`,
      })
    }
  }, [nftsToShow])

  return (
    <>
      {(error || noNftsFound) && (
        <div className={styles.msgContainer}>
          {error && <p className={styles.error}>{'Error loading NFTs 😞'}</p>}
          {noNftsFound && <p className={styles.noNfts}>You have no NFTs</p>}
        </div>
      )}
      {!error && !loading && nftsToShow && (
        <>
          <section data-testid="nft-gallery" className={styles.nftsContainer} aria-label="NFTs">
            {nftsToShow.map((nft) => {
              const imageUrl = getGatewayUrl({ nft, url: nft.imageUrl })
              return (
                <Card
                  className={styles.nftCard}
                  onClick={() => dispatch(setActiveNFT(nft))}
                  variant={'outlined'}
                  data-testid="nft-card"
                  sx={{ margin: '10px', width: '320px' }}
                  key={`${nft.collectionAddress}-${nft.tokenId}`}
                >
                  <CardMedia
                    component="img"
                    height="300px"
                    width="auto"
                    image={imageUrlFormat(imageUrl)}
                    alt={`${nft.name} image`}
                    className={styles.nftImage}
                    onError={(e) =>
                      ReactGA.event('NFT_Image_Load_Error', {
                        category: 'Gallery/NFTs',
                        action: 'NFTs Image Failed to Load',
                        image_host: imageUrl ? new URL(imageUrl).hostname : '',
                      })
                    }
                    crossOrigin="anonymous"
                  />
                  <Typography noWrap className={styles.nftTitle}>
                    {nft.name}
                  </Typography>
                </Card>
              )
            })}
          </section>
          {(hasNextPage || loading) && (
            <div ref={sentryRef}>
              <p className={styles.loading}>Loading...</p>
            </div>
          )}
        </>
      )}
    </>
  )
}
