export function shortenedAddress(address: string) {
  return address.slice(0, 8) + '...' + address.slice(address.length - 6)
}

/**
 * Cache busting for images returns a new url with the hour timestamp
 * This is to prevent the browser from caching images longer than an hour
 * @param imageUrl
 * @returns {string} the new url
 */
export function imageUrlFormat(imageUrl: string | undefined) {
  // Only add the cache busting query parameter to http-based URLs. There might
  // be other URLs (e.g., data: ones) where query parameters are not an
  // established convention.
  if (imageUrl?.startsWith('http')) {
    return `${imageUrl}?t=${Math.floor(Date.now() / 1000 / 60 / 60)}`
  }
  return imageUrl
}
