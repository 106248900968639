import AddressInput from 'app/components/AddressInput'
import styles from './Landing.module.scss'
interface Props {
  updateAddress: (address: string) => void
  networkId: string
}

export function Landing({ updateAddress, networkId }: Props) {
  return (
    <div className={styles.container}>
      <h1 className={styles.mainHeader}>Explore NFTs</h1>
      <div className={styles.inputContainer}>
        <h3 className={styles.inputTitle}>Paste an address to view galleries</h3>
        <AddressInput updateAddress={updateAddress} networkId={networkId} />
      </div>
    </div>
  )
}
