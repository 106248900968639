// don't name this file index. workaround to avoid naming conflicts
// on import with node's constants file

export const SECONDS_IN_A_YEAR = 60 * 60 * 24 * 365
export const MAX_CONCURRENCY = 20
export const MAX_RETRIES = 3

export const NFT_QUERY_LIMIT = 10

const CELO_EXPLORER_ENDPOINT = 'https://explorer.celo.org/mainnet'
const OPENSEA_ETHEREUM_URL = 'https://opensea.io/assets/ethereum'
const OPENSEA_POLYGON_URL = 'https://opensea.io/assets/matic'

export interface NetworkMetadata {
  humanReadableName: string
  externalViewDescription: string
  getExternalViewUrl(contractAddress: string, tokenId: string): string
}

export const networkInfos: Record<string, NetworkMetadata> = {
  'celo-mainnet': {
    humanReadableName: 'Celo',
    externalViewDescription: 'View on Celo Explorer',
    getExternalViewUrl: (contractAddress: string, tokenId: string) =>
      `${CELO_EXPLORER_ENDPOINT}/token/${contractAddress}/instance/${tokenId}/metadata`,
  },
  'ethereum-mainnet': {
    humanReadableName: 'Ethereum',
    externalViewDescription: 'View on OpenSea',
    getExternalViewUrl: (contractAddress: string, tokenId: string) =>
      `${OPENSEA_ETHEREUM_URL}/${contractAddress}/${tokenId}`,
  },
  'polygon-pos-mainnet': {
    humanReadableName: 'Polygon',
    externalViewDescription: 'View on OpenSea',
    getExternalViewUrl: (contractAddress: string, tokenId: string) =>
      `${OPENSEA_POLYGON_URL}/${contractAddress}/${tokenId}`,
  },
} as const
