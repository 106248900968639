import { ContractKitProvider, Mainnet } from '@celo-tools/use-contractkit'
import { StyledEngineProvider } from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga4'
import { Provider } from 'react-redux'
import { store } from 'store/store'
import './index.css'
import Router from './router/Router'

ReactGA.initialize('G-SYKZ1MX15H', { testMode: process.env.REACT_APP_GA_ENABLED !== 'true' })

ReactDOM.render(
  <React.StrictMode>
    <ContractKitProvider
      dapp={{
        name: 'nft viewer',
        description: 'nft viewer for celo',
        url: '',
        icon: '',
      }}
      network={Mainnet}
    >
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <Router />
        </StyledEngineProvider>
      </Provider>
    </ContractKitProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
