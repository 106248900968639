import AddressInput from 'app/components/AddressInput'
import { shortenedAddress } from 'helpers/utils'
import { useState } from 'react'
import ReactGA from 'react-ga4'
import { FiCheck, FiCopy } from 'react-icons/fi'
import styles from './Gallery.module.scss'
import NFTs from './NFTs'

interface Props {
  loading: boolean
  address: string
  updateAddress: (address: string) => void
  networkId: string
}

export function Gallery({ address, updateAddress, loading, networkId }: Props) {
  const [copyingAddress, setCopyingAddress] = useState(false)

  const copyAddress = () => {
    if (!address) return
    ReactGA.event('Copy_Address', {
      category: 'Gallery',
      action: 'Copy Address',
    })
    setCopyingAddress(true)
    setTimeout(() => {
      setCopyingAddress(false)
    }, 1000)
    navigator.clipboard.writeText(address)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.inputContainer}>
          <AddressInput updateAddress={updateAddress} networkId={networkId} />
        </div>
        {!loading && (
          <>
            <p className={styles.addressLabel}>Viewing NFTs for:</p>
            <div className={styles.addressContainer}>
              <span className={styles.address}>{shortenedAddress(address)}</span>
              {!copyingAddress ? (
                <FiCopy data-cy="Copy-Address" className={styles.copyAddress} onClick={copyAddress} />
              ) : (
                <FiCheck data-cy="Address-Copied" className={styles.addressCopied} />
              )}
            </div>
          </>
        )}
      </div>
      <NFTs address={address} />
    </div>
  )
}
