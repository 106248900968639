import { addressFromNomName } from 'fetchers/nom'

export async function isValidAddress(address: string | null) {
  if (address?.endsWith('.nom')) {
    address = await addressFromNomName(address)
  }
  if (address && address.length === 42 && address.startsWith('0x')) {
    return true
  }
  return false
}
