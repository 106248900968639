import { isValidAddress } from 'helpers/address'
import { saveValue, StorageKey } from 'helpers/storage'
import { ChangeEvent, useState } from 'react'
import { useAsync } from 'react-async-hook'
import ReactGA from 'react-ga4'
import { fetchNFTs } from 'store/nfts'
import { useAppDispatch, useAppSelector } from 'store/store'
import styles from './AddressInput.module.scss'

const humanReadableNetworkNames: Record<string, string> = {
  'celo-mainnet': 'Celo',
  'ethereum-mainnet': 'Ethereum',
  'polygon-pos-mainnet': 'Polygon POS',
} as const

interface Props {
  updateAddress: (address: string) => void
  networkId: string
}

export function AddressInput({ updateAddress, networkId }: Props) {
  const [address, setAddress] = useState('')
  const { loading } = useAppSelector((state) => state.nfts)

  const dispatch = useAppDispatch()

  useAsync(async () => {
    if (address && (await isValidAddress(address))) {
      ReactGA.event('Address_Searched', {
        category: 'Address Input',
        action: 'Address Searched',
      })
      await dispatch(fetchNFTs({ address, networkId }))
      updateAddress(address)
      saveValue(StorageKey.Address, address)
      setAddress('')
    }
  }, [address])

  const handleAddressUpdate = async (event: ChangeEvent<HTMLInputElement>) => {
    const newAddress = event.currentTarget.value
    setAddress(newAddress)
  }

  const pasteFromClipboard = async () => {
    const pastedAddress = await navigator.clipboard.readText()
    ReactGA.event('Paste_From_Clipboard', {
      category: 'Address Input',
      action: 'Paste From Clipboard',
    })
    setAddress(pastedAddress)
  }

  const preventSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  const humanReadableNetworkName = humanReadableNetworkNames[networkId]
  const addressPlaceholder = humanReadableNetworkName ? `${humanReadableNetworkName} Address` : 'Address'

  return (
    <form aria-label="form" className={styles.form} onSubmit={preventSubmit}>
      <div className={styles.inputContainer}>
        <input
          type="text"
          value={address}
          className={styles.input}
          placeholder={addressPlaceholder}
          onChange={handleAddressUpdate}
          disabled={loading}
          data-cy="Address-Input"
        />
        <p className={styles.pasteText} onClick={pasteFromClipboard} data-cy="Paste-Clipboard">
          Paste & Go
        </p>
      </div>
      {loading && (
        <p className={styles.loading} data-cy="Loading">
          Loading...
        </p>
      )}
    </form>
  )
}
