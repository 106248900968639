import { Address } from '@celo/base/lib/address'
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NFTCollections, NFTInfo } from 'fetchers/nftTypes'

import { DummyNFTIndex } from 'fetchers/dummy'
import { TatumNFTIndex } from 'fetchers/tatum'
import { ValoraNFTIndex } from 'fetchers/valora'

interface NFTsState {
  loading: boolean
  error: boolean
  nftsInfo: NFTInfo[]
  nftCollections: NFTCollections
  newNFTAddresses: string[]
  activeNFT: NFTInfo | null
}

const initialState: NFTsState = {
  loading: false,
  error: false,
  nftsInfo: [],
  nftCollections: {},
  newNFTAddresses: [],
  activeNFT: null,
}

let nftIndex
if (process.env.REACT_APP_NFT_INDEX === 'tatum') {
  nftIndex = new TatumNFTIndex({})
} else if (process.env.REACT_APP_NFT_INDEX === 'valora') {
  nftIndex = new ValoraNFTIndex({})
} else {
  nftIndex = new DummyNFTIndex()
}

export const clearAddressAction = createAction<void>('general/clearAddress')
export const fetchNFTs = createAsyncThunk<NFTInfo[], { address: Address; networkId: string }>(
  'nfts/fetch',
  nftIndex.balance.bind(nftIndex),
)
export const refreshNFTs = createAction<void>('nfts/refresh')

export const nftsSlice = createSlice({
  name: 'nfts',
  initialState,
  reducers: {
    setActiveNFT: (state, action) => {
      state.activeNFT = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearAddressAction.type, (state) => {
        return initialState
      })
      .addCase(refreshNFTs.type, (state) => {
        return initialState
      })
      .addCase(fetchNFTs.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(fetchNFTs.rejected, (state) => {
        state.loading = false
        state.error = true
      })
      .addCase(fetchNFTs.fulfilled, (state, action) => {
        state.loading = false
        state.nftsInfo = action.payload
      })
  },
})

export const { setActiveNFT } = nftsSlice.actions
