import { formatBytes32String } from '@ethersproject/strings'
import { contract } from 'helpers/contractKit'
import nomInterface from 'abis/INom.json'

const NOM_ADDRESS = '0xABf8faBbC071F320F222A526A2e1fBE26429344d'
const EMPTY_ADDRESS = '0x0000000000000000000000000000000000000000'

export async function addressFromNomName(nomName: string): Promise<string | null> {
  const nom = await contract(nomInterface.abi, NOM_ADDRESS)
  const nameBytes = formatBytes32String(nomName.substr(0, nomName.length - 4))
  const resolvedAddress = await nom.methods.resolve(nameBytes).call()
  if (resolvedAddress !== EMPTY_ADDRESS) {
    return resolvedAddress
  }
  const ownerAddress = await nom.methods.nameOwner(nameBytes).call()
  if (ownerAddress !== EMPTY_ADDRESS) {
    return ownerAddress
  }
  return null
}
